<template>
  <div class="container">
    <van-sticky>
      <van-nav-bar title="线下课程" left-arrow @click-left="$router.back()" />
    </van-sticky>

    <van-tabs v-model="activeName" sticky>
      <van-tab title="已预约" name="a">
        <div class="tabcontent">
          <CourseofflineItemList
            :Type="2"
            :toDetail="toCourseDetail"
          ></CourseofflineItemList>
        </div>
      </van-tab>

      <van-tab title="已签到" name="b">
        <div class="tabcontent">
          <CourseofflineItemList
            :Type="3"
            :toDetail="toCourseDetail"
          ></CourseofflineItemList>
        </div>
      </van-tab>

      <van-tab title="待评价" name="d">
        <div class="tabcontent">
          <CourseofflineItemList
            :Type="5"
            :toDetail="toCourseComment"
          ></CourseofflineItemList>
        </div>
      </van-tab>

      <van-tab title="授课" name="c">
        <div class="tabcontent">
          <CourseofflineItemList
            :Type="4"
            :toDetail="toDetail"
          ></CourseofflineItemList>
        </div>
      </van-tab>
       <van-tab title="学院评" name="e" v-if="hasSchoolRole">
        <div class="tabcontent">
          <CourseofflineItemList
            :Type="6"
            :toDetail="toCourseComment"
          ></CourseofflineItemList>
        </div>
      </van-tab>

      
    </van-tabs>
  </div>
</template>

<script>
import CourseofflineItemList from "../components/CourseofflineItemList";
import { HasRole } from "@/api/user";

export default {
  data() {
    return {
      activeName: "a",
      hasSchoolRole:false
    };
  },
  components: {
    CourseofflineItemList,
  },
  created() {
    this.initData();
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (to.query != from.query) {
        this.initData();
        this.onSearch();
      }
    },
  },
  mounted() {},

  methods: {
    async initData(){
      ///api/CourseOffline/ListSignInAndComment
      let nameReuslt = await HasRole("SchoolComment");
        this.hasSchoolRole = nameReuslt.data.data;
    },
    toCourseDetail(item) {
      this.$router.push({
        path: "/course/courseofflinedetail",
        query: { para: item.id },
      });
    },
    toCourseComment(item) {
      this.$router.push({
        path: item.commentUrl,
        
      });
    },
    toDetail(item) {
      this.$router.push({
        path: "/user/mycourseofflinedetail",
        query: { para: item.id },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.w100 {
  /deep/ img {
    width: 100%;
    max-height: 200px;
  }
}
.zntitle {
  height: 80px;
}
.container {
  height: 100%;
  //overflow-y: auto;
  //box-sizing: border-box;
    /deep/.van-tabs
{
   height:100%;
}
 /deep/.tabcontent
{
   height:100%;
}
 /deep/.van-tabs__content
{
  height:100%;
}

}
.van-tabs {
  height: 100%;
  display: flex;
  flex-direction: column;
  /deep/ .van-tabs__wrap {
    height: 36px;
    padding-right: 36px;
    .van-tab {
      line-height: 36px;
    }
    .van-tabs__line {
      background-color: #3296fa;
      height: 2px;
    }
  }
  /deep/ .van-tabs__content {
    flex: 1;
    overflow: hidden;
  }
  /deep/ .van-tab__pane {
    height: 100%;
    .scroll-wrapper {
      height: 100%;
      overflow-y: auto;
    }
  }
}
.detail {
  padding: 46px 10px 44px;
  .title {
    font-size: 18px;
    line-height: 2;
  }
  .zan {
    text-align: center;
    padding: 10px 0;
    .active {
      border-color: red;
      color: red;
    }
  }
  .author {
    padding: 10px 0;
    display: flex;
    position: sticky;
    background-color: #fff;
    top: 46px;
    z-index: 2;
    .text {
      flex: 1;
      padding-left: 10px;
      line-height: 1.5;
      .name {
        font-size: 14px;
        margin: 0;
      }
      .time {
        margin: 0;
        font-size: 12px;
        color: #999;
      }
    }
  }
  .content {
    padding: 20px 0;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-all;
    /deep/ img {
      max-width: 100%;
      background: #f9f9f9;
    }
    /deep/ code {
      white-space: pre-wrap;
    }
  }
}
</style>
